import { ViewportNavbar } from "./navbar";

export const ViewportLayout: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  return (
    <div className="flex h-full overflow-hidden">
      <ViewportNavbar />
      <div className="min-w-0 flex-1 p-2 pl-0">
        <div className="h-full w-full min-w-0 overflow-hidden rounded-lg border-[0.5px] bg-slate-900/40 shadow-xl">
          <div className="flex h-full w-full flex-col">
            <div className="flex h-full flex-1 overflow-hidden">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
