import { composeRefs } from "@radix-ui/react-compose-refs";
import * as React from "react";
import { type InputProps, inputVariants } from "./input";

import { cn } from "@ui/lib/utils";

export type TextFieldProps = InputProps & {
  inputClassName?: string;
};

export const TextFieldRoot = React.forwardRef<HTMLInputElement, TextFieldProps>(
  ({ className, children, inputClassName, ...props }, forwardedRef) => {
    const inputRef = React.useRef<HTMLInputElement>(null);

    return (
      <div
        className={cn(
          inputVariants(props),
          "focus-within:ring-ring flex min-w-0 items-center focus-within:ring-1",
          className,
        )}
        style={props.style}
        onPointerDown={(e) => {
          /**
           * Adapter from Radix Themes:
           * https://github.com/radix-ui/themes/blob/main/packages/radix-ui-themes/src/components/text-field.tsx
           */
          const target = e.target as HTMLElement;
          if (target.closest("input, button, a")) return;

          const input = inputRef.current;
          if (!input) return;

          // Same selector as in the CSS to find the right slot
          const isRightSlot = target.closest(`
            .TextFieldSlot[data-side='right'],
            .TextFieldSlot:not([data-side='right']) ~ .TextFieldSlot:not([data-side='left'])
          `);

          const cursorPosition = isRightSlot ? input.value.length : 0;

          requestAnimationFrame(() => {
            // Only some input types support this, browsers will throw an error if not supported
            // See: https://developer.mozilla.org/en-US/docs/Web/API/HTMLInputElement/setSelectionRange#:~:text=Note%20that%20according,not%20support%20selection%22.
            try {
              input.setSelectionRange(cursorPosition, cursorPosition);
            } catch (e) {
              console.error(e);
            }
            input.focus();
          });
        }}
      >
        <input
          type={props.type}
          spellCheck="false"
          placeholder={props.placeholder}
          ref={composeRefs(inputRef, forwardedRef)}
          value={props.value}
          onChange={props.onChange}
          className={cn(
            "min-w-0 bg-transparent outline-none placeholder:text-slate-400",
            inputClassName,
          )}
          {...props}
        />
        {children}
      </div>
    );
  },
);
TextFieldRoot.displayName = "TextField.Root";

type TextFieldSlotElement = React.ElementRef<"div">;

export const TextFieldSlot = React.forwardRef<
  TextFieldSlotElement,
  {
    className?: string;
    color?: string;
    side: "left" | "right";
    children: React.ReactNode;
  }
>((props, forwardedRef) => {
  const { className, color, side, ...slotProps } = props;
  return (
    <div
      data-accent-color={color}
      data-side={side}
      {...slotProps}
      ref={forwardedRef}
      className={cn(
        "TextFieldSlot",
        side !== "right" && "-order-1 mr-1.5",
        className,
      )}
    />
  );
});
TextFieldSlot.displayName = "TextField.Slot";
