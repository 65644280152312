import Image from "next/image";

import { Button } from "@ui/components/ui/button";
import { cn } from "@ui/lib/utils";

export const ThreadAssignee = () => {
  return (
    <div>
      <Button size="sm" variant="ghost" className="h-7 px-2 text-slate-400">
        <div className="relative mr-[10px] h-4 w-4">
          <Image
            alt="Ethan's avatar"
            src="/landing/avatars/ethan.png"
            width={16}
            height={16}
            className="h-4 w-4 rounded-full"
          />
          <div
            className={cn(
              "absolute -bottom-[1px] -right-[1px] h-[6px] w-[6px] rounded-full bg-green-600 outline outline-[#141D2E] transition-colors",
            )}
          />
        </div>
        <span className="text-slate-50">Ethan Lerner</span>
      </Button>
    </div>
  );
};
