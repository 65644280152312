"use client";

import { ChevronDown } from "lucide-react";
import Link from "next/link";
import { easeOutQuart } from ".";

import { Button } from "@ui/components/ui/button";

export const Cta = () => {
  return (
    <div
      className="mt-10 flex gap-4"
      style={{
        animation: `cta 0.75s ${easeOutQuart} 0.3s normal backwards`,
      }}
    >
      <Button className="rounded-xl px-4 text-base" size="lg" asChild>
        <Link href="/auth/login">Get started for free</Link>
      </Button>
      <Button
        className="rounded-xl px-4 text-base hover:bg-slate-800/50"
        size="lg"
        variant="ghost"
        onClick={() => {
          const element = document.getElementById("teams-section-header");
          if (!element) return;

          const elementPosition = element.getBoundingClientRect().top;
          const offsetPosition = elementPosition + window.scrollY - 130;

          window.scrollTo({
            top: offsetPosition,
            behavior: "smooth",
          });
        }}
      >
        Learn more
        <ChevronDown className="ml-1 h-4 w-4 text-slate-300" />
      </Button>
    </div>
  );
};
