import { Plus } from "lucide-react";

import { Button } from "@ui/components/ui/button";

export const tags = [
  {
    name: "B2B",
    color: "#ef4444",
  },
  {
    name: "Referral",
    color: "#3b82f6",
  },
  {
    name: "Europe",
    color: "#a855f7",
  },
];

export const ExternalTags = () => {
  return (
    <>
      <div className="space-y-3">
        <div className="ml-2 text-xs font-medium text-slate-400">Tags</div>
        <div className="flex flex-wrap gap-[6px] gap-y-2">
          {tags.map((tag, i) => (
            // biome-ignore lint/suspicious/noArrayIndexKey: won't change
            <Tag key={i} name={tag.name} color={tag.color} />
          ))}
          <Button
            variant="ghost"
            size="sm"
            className="h-max rounded-full p-1 text-slate-300"
          >
            <Plus className="h-4 w-4" />
          </Button>
        </div>
      </div>
    </>
  );
};

interface TagProps {
  name: string;
  color: string;
}

export const Tag: React.FC<TagProps> = ({ name, color }) => {
  return (
    <div className="flex w-max items-center gap-[6px] overflow-hidden rounded-full border border-slate-700 p-[3px] px-2 transition-colors hover:border-slate-600">
      <div
        className="h-[10px] w-[10px] min-w-[10px] rounded-full"
        style={{ backgroundColor: color }}
      />
      <div className="overflow-hidden overflow-ellipsis whitespace-nowrap text-xs font-semibold">
        {name}
      </div>
    </div>
  );
};
