export const ExternalNotes = () => {
  return (
    <div className="w-full space-y-1">
      <div className="ml-2 text-xs font-medium text-slate-400">Notes</div>
      <div className="flex w-full flex-wrap gap-[6px] gap-y-2 text-slate-200">
        <div className="w-full overflow-hidden p-2 text-sm text-slate-500 outline-none">
          Add notes...
        </div>
      </div>
    </div>
  );
};
