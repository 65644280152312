import { Hexagon } from "lucide-react";

import { Button } from "@ui/components/ui/button";

const status = {
  color: "#f59e0b",
  name: "Warm",
};

export const ExternalStatus = () => {
  return (
    <div>
      <Button size="sm" variant="ghost" className="h-7 px-2 text-slate-400">
        <div className="relative mr-[10px] h-4 w-4">
          <Hexagon
            className="h-4 w-4 stroke-[3px]"
            style={{ stroke: status.color }}
          />
        </div>
        <span className="text-slate-50">{status.name}</span>
      </Button>
    </div>
  );
};
