import { ExternalDetails } from "./external-details";
import { ExternalNotes } from "./external-notes";
import { ExternalStatus } from "./external-status";
import { ExternalTags } from "./external-tags";
import { ThreadAssignee } from "./thread-assignee";

import { Separator } from "@ui/components/ui/separator";

export const Sidebar = () => {
  return (
    <div className="relative flex h-full w-[260px] min-w-[260px] max-w-[260px] flex-col self-end border-l-[0.5px] bg-slate-800/35">
      <ExternalDetails
        displayName="Kevin"
        username="KevinPicchi"
        bannerUrl="/landing/banner.jpeg"
        image="/landing/avatars/kevin.jpg"
        description={
          <>
            Product & Marketing 💬 TweetDM.com 📷 FreezingCam.com 🌐
            langbuddy.ai
          </>
        }
        followers="936"
        following="711"
        location="London & Switzerland 🇨🇭"
        url="tweetdm.com"
      />
      <Separator />
      <div className="space-y-5 p-4 px-5">
        <div className="space-y-3">
          <ExternalStatus />
          <ThreadAssignee />
        </div>
        <ExternalTags />
        <ExternalNotes />
      </div>
    </div>
  );
};
