import type { LucideIcon } from "lucide-react";
import type React from "react";

import { buttonVariants } from "@ui/components/ui/button";
import { cn } from "@ui/lib/utils";

export interface NavItemProps {
  disabled?: boolean;
  title: string;
  label?: string;
  icon?: LucideIcon;
  isActive?: boolean;
  isSubItem?: boolean;
}

export const NavItem: React.FC<NavItemProps> = ({
  title,
  label,
  icon: Icon,
  isActive,
  isSubItem,
  disabled,
}) => {
  const active = isActive;
  const variant = active ? "default" : "ghost";

  return (
    <div
      className={cn(
        buttonVariants({ variant, size: "sm" }),
        variant === "default"
          ? "dark:bg-slate-800/75 dark:text-white dark:hover:text-white"
          : "hover:bg-slate-800/45",
        "group h-min w-full cursor-pointer justify-start rounded-sm px-[7px] py-[5px] text-xs font-medium text-slate-200 transition-none",
        isSubItem && "pl-[10.5px]",
        disabled && "text-slate-400 hover:text-slate-400",
      )}
    >
      {Icon && (
        <Icon
          className={cn(
            "mr-2 h-4 w-4 stroke-[2.375px] text-slate-400/90 group-hover:text-slate-50",
            disabled && "text-slate-500 group-hover:text-slate-500",
          )}
        />
      )}
      {title}
      {label && (
        <span
          className={cn(
            "ml-auto",
            variant === "default" && "text-background dark:text-white",
          )}
        >
          {label}
        </span>
      )}
    </div>
  );
};
