"use client";

import { BoxIcon } from "lucide-react";
import { SectionHeader } from "../../components/section-header";
import { PipelineCard } from "./cards/pipeline";
import { SharedAccountsCard } from "./cards/shared-accounts";
import { TagsCard } from "./cards/tags";

export const TeamsSection = () => {
  return (
    <section className="space-y-16 px-4">
      <SectionHeader
        id="teams-section-header"
        title="Keep your team in the loop"
        copy="Link all of your X accounts, assign leads to your reps, and keep track of your prospects."
        icon={BoxIcon}
        headerClassName="max-w-sm"
        copyClassName="max-w-md"
      />
      <div className="mx-auto grid max-w-5xl grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
        <SharedAccountsCard />
        <PipelineCard />
        <TagsCard />
      </div>
    </section>
  );
};
