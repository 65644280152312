export const BACKGROUND_HSL = "223.78, 57.89%, 7.45%";

export const gradient = (hsl: string, direction = "top") => `
  linear-gradient(
    to ${direction},
    hsl(${hsl}) 0%,
    hsla(${hsl}, 0.987) 8.1%,
    hsla(${hsl}, 0.951) 15.5%,
    hsla(${hsl}, 0.896) 22.5%,
    hsla(${hsl}, 0.825) 29%,
    hsla(${hsl}, 0.741) 35.3%,
    hsla(${hsl}, 0.648) 41.2%,
    hsla(${hsl}, 0.55) 47.1%,
    hsla(${hsl}, 0.45) 52.9%,
    hsla(${hsl}, 0.352) 58.8%,
    hsla(${hsl}, 0.259) 64.7%,
    hsla(${hsl}, 0.175) 71%,
    hsla(${hsl}, 0.104) 77.5%,
    hsla(${hsl}, 0.049) 84.5%,
    hsla(${hsl}, 0.013) 91.9%,
    hsla(${hsl}, 0) 100%
  )
`;

export const reverseGradientMask = (
  hsl: string,
  height: string,
  direction = "bottom",
) => `
  linear-gradient(
    to ${direction},
    hsla(${hsl}, 0) 0%,
    hsla(${hsl}, 0.013) 8.1%,
    hsla(${hsl}, 0.049) 15.5%,
    hsla(${hsl}, 0.104) 22.5%,
    hsla(${hsl}, 0.175) 29%,
    hsla(${hsl}, 0.259) 35.3%,
    hsla(${hsl}, 0.352) 41.2%,
    hsla(${hsl}, 0.45) 47.1%,
    hsla(${hsl}, 0.55) 52.9%,
    hsla(${hsl}, 0.648) 58.8%,
    hsla(${hsl}, 0.741) 64.7%,
    hsla(${hsl}, 0.825) 71%,
    hsla(${hsl}, 0.896) 77.5%,
    hsla(${hsl}, 0.951) 84.5%,
    hsla(${hsl}, 0.987) 91.9%,
    hsl(${hsl}) 100%
  ) ${height}
`;
