import React from "react";
import { NavItem, type NavItemProps } from "./nav-item";

import { Separator } from "@ui/components/ui/separator";

interface ContainerProps {
  items: (NavItemProps & {
    separator?: boolean;
    subItems?: NavItemProps[];
  })[];
}

export const NavContainer: React.FC<ContainerProps> = ({ items }) => {
  return (
    <div className="flex flex-col gap-[1px]">
      {items.map((item, i) => (
        // biome-ignore lint/suspicious/noArrayIndexKey: won't change
        <React.Fragment key={i}>
          <NavItem
            title={item.title}
            icon={item.icon}
            isActive={item.isActive}
            disabled={item.disabled}
          />
          {!!item.subItems?.length && (
            <div className="ml-[14px] border-l border-slate-800 pl-2">
              {item.subItems.map((subItem, i) => (
                <NavItem
                  // biome-ignore lint/suspicious/noArrayIndexKey: won't change
                  key={i}
                  title={subItem.title}
                  isActive={subItem.isActive}
                  isSubItem
                />
              ))}
            </div>
          )}
          {item.separator && (
            <div className="my-2 px-1">
              <Separator />
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};
