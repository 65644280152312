"use client";

import { motion, useSpring, useTransform } from "framer-motion";
import type React from "react";
import { useEffect, useRef } from "react";

import { cn } from "@ui/lib/utils";

export const CardWrapper: React.FC<
  React.PropsWithChildren<{ className?: string }>
> = ({ children, className }) => {
  const lanternConfig = {
    damping: 15,
    mass: 0.27,
    stiffness: 55,
  };

  const containerRef = useRef<HTMLDivElement>(null);

  const lanternX = useSpring(0, lanternConfig);
  const lanternY = useSpring(0, lanternConfig);

  const gradient = useTransform(
    () =>
      `radial-gradient(circle at ${lanternX.get()}px ${lanternY.get()}px, #334155 10%, #0f172a 90%)`,
  );
  const highlightGradient = useTransform(
    () =>
      `radial-gradient(circle at ${lanternX.get()}px ${lanternY.get()}px, #60a5fa 15%, #0f172a 50%)`,
  );
  const innerGradient = useTransform(
    () =>
      `radial-gradient(circle at ${lanternX.get()}px ${lanternY.get()}px, #0f172a 10%, #060D1C 90%)`,
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: allowed
  useEffect(() => {
    if (!containerRef.current) return;

    const handleMouseMove = (e: MouseEvent) => {
      const relativeX =
        e.clientX - containerRef.current!.getBoundingClientRect().left;
      const relativeY =
        e.clientY - containerRef.current!.getBoundingClientRect().top;

      lanternX.set(relativeX);
      lanternY.set(relativeY);
    };

    const ref = containerRef.current;
    ref.addEventListener("mousemove", handleMouseMove);
    return () => {
      ref.removeEventListener("mousemove", handleMouseMove);
    };
  }, [containerRef]);

  return (
    <div
      className={cn("overflow-hidden rounded-2xl bg-slate-900/30", className)}
    >
      <div
        ref={containerRef}
        className="group relative h-full w-full overflow-hidden bg-slate-900 p-px"
      >
        <motion.div
          className="pointer-events-none absolute left-0 top-0 h-full w-full opacity-0 transition-opacity duration-500 group-hover:opacity-100"
          style={{
            backgroundImage: gradient,
          }}
        />
        <motion.div
          className="pointer-events-none absolute left-0 top-0 h-full w-full opacity-0 transition-opacity duration-500 group-hover:opacity-20"
          style={{
            backgroundImage: highlightGradient,
          }}
        />
        <div className="relative z-10 h-full w-full overflow-hidden rounded-[15.5px] bg-[#060D1C]">
          <motion.div
            className="absolute left-0 top-0 h-full w-full opacity-0 transition-opacity duration-500 group-hover:opacity-100"
            style={{
              backgroundImage: innerGradient,
            }}
          />
          {children}
        </div>
      </div>
    </div>
  );
};
