import { UsersIcon } from "lucide-react";
import Image from "next/image";

import { CardCopy } from "@/modules/landing/components/card-copy";
import { CardWrapper } from "@/modules/landing/components/card-wrapper";
import { DateSeparator } from "@/modules/landing/hero/viewport/chat/date-separator";
import { Message } from "@/modules/landing/hero/viewport/chat/message";
import { MessageGroup } from "@/modules/landing/hero/viewport/chat/message-group";

import { cn } from "@ui/lib/utils";

export const SharedAccountsCard = () => {
  return (
    <CardWrapper className="h-[300px]">
      <figure className="pointer-events-none absolute left-1/2 top-0 z-10 -ml-2 mt-2 w-[520px] -translate-x-1/2 scale-[0.8] select-none lg:w-[316px]">
        <div className="opacity-70">
          <MessageGroup>
            <Message
              internal
              time="12:00"
              content="sure, sounds good!"
              authorImage="/landing/avatars/ethan.png"
            />
          </MessageGroup>
        </div>
        <DateSeparator date="July 21st" />
        <MessageGroup>
          <Message
            internal
            time="14:17"
            content="hey—just following up... any updates?"
            authorImage="/landing/avatars/generic.png"
          />
        </MessageGroup>
        <div className="absolute left-[262px] top-[130px] w-max rounded-md border-[0.5px] bg-slate-900 p-2 px-3 shadow-lg lg:left-[210px]">
          <div className="flex items-center gap-3">
            <div className="relative h-6 w-6">
              <Image
                alt="Online team member's avatar"
                src="/landing/avatars/generic.png"
                width={24}
                height={24}
                className="h-6 w-6 rounded-full"
              />
              <div
                className={cn(
                  "absolute -bottom-[1px] -right-[1px] h-[8px] w-[8px] rounded-full bg-green-600 outline outline-[#141D2E]",
                )}
              />
            </div>
            <div className="flex flex-col">
              <div className="text-sm font-medium text-slate-50">
                Timothy Okuneva
              </div>
              <div className="text-xs text-slate-400">online</div>
            </div>
          </div>
        </div>
      </figure>
      <CardCopy
        icon={UsersIcon}
        title="Shared accounts"
        copy="Anyone from your team can message anyone from any account."
      />
    </CardWrapper>
  );
};
