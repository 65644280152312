import { AppViewport } from "./app-viewport";
import { Cta } from "./cta";

export const easeInOutQuart = "cubic-bezier(0.83, 0, 0.17, 1)";
export const easeOutQuart = "cubic-bezier(0.25, 1, 0.5, 1)";

const titleAnimation = `
  @keyframes title {
    0% { opacity: 0; transform: translateY(-32px); }
    100% { opacity: 1; transform: translateY(0); }
  }
`;
const subAnimation = `
  @keyframes sub {
    0% { opacity: 0; transform: translateY(50px); }
    100% { opacity: 1; transform: translateY(0); }
  }
`;
const ctaAnimation = `
  @keyframes cta {
    0% { opacity: 0; transform: translateY(50px); }
    100% { opacity: 1; transform: translateY(0); }
  }
`;

export const Hero = () => {
  return (
    <section className="relative mx-auto w-full max-w-5xl pt-28">
      <style>{titleAnimation}</style>
      <style>{subAnimation}</style>
      <style>{ctaAnimation}</style>
      <div className="absolute -right-8 top-20 h-48 w-96 -rotate-45 scale-75 bg-slate-900 blur-[140px]" />
      <div className="absolute right-0 top-0 h-48 w-96 -rotate-45 bg-slate-800 blur-[120px]" />
      <div className="relative z-10 mb-16 flex flex-col items-center justify-center px-8">
        <h1
          className="relative mt-5 w-full max-w-xl text-center text-5xl font-medium leading-[1.1] sm:text-6xl "
          style={{
            animation: `title 0.6s ${easeInOutQuart}`,
          }}
        >
          <div className="absolute left-0 top-0 opacity-30 blur-sm">
            The missing inbox and CRM for X
          </div>
          <div className="relative">The missing inbox and CRM for X</div>
        </h1>
        <h3
          className="mt-8 max-w-md text-center text-lg font-medium text-slate-300/75 sm:text-xl"
          style={{
            animation: `sub 0.75s ${easeOutQuart} 0.2s normal backwards`,
          }}
        >
          Boost sales, track conversations, and enhance team collaboration on X
          with TweetDM.
        </h3>
        <Cta />
      </div>
      <AppViewport />
    </section>
  );
};
