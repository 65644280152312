"use client";

import { ListFilterIcon, Plus, Search } from "lucide-react";
import { useRef } from "react";
import { tags } from "../sidebar/external-tags";
import { Thread, type ThreadProps } from "./thread";
import { ThreadHighlight } from "./thread-highlight";

import { Button } from "@ui/components/ui/button";
import { TextFieldRoot, TextFieldSlot } from "@ui/components/ui/text-field";

export const threads: ThreadProps[] = [
  {
    image: "/landing/avatars/astronaut.png",
    name: "Matthew Ortiz",
    lastMessage: "yeah, for the most part at least",
    time: "now",
    isUnread: true,
  },
  {
    image: "/landing/avatars/kevin.jpg",
    name: "Kevin",
    lastMessage: "nice, let's do a demo at 2 then",
    time: "5m",
    tags,
  },
  {
    image: "/landing/avatars/superhuman.jpg",
    name: "Superhuman",
    lastMessage: "We're definitely big fans for sure",
    time: "11m",
    lastMessageAuthorImage: "/landing/avatars/ethan.png",
  },
  {
    image: "/landing/avatars/zohaib.jpg",
    name: "Zohaib Rattu",
    lastMessage: "lmk if that timeline works or",
    time: "14m",
    lastMessageAuthorImage: "/landing/avatars/generic.png",
    tags: [{ color: "#f59e0b", name: "D2C" }],
  },
  {
    image: "/landing/avatars/wink.png",
    name: "Greg | 10x Your Outreach",
    lastMessage: "what stage would that be considered",
    time: "20m",
    tags: tags.filter((x) => x.name === "Referral"),
    isUnread: true,
  },
  {
    image: "/landing/avatars/ghost.png",
    name: "Nick Hammes 🚀",
    lastMessage: "did you end up deciding if we should",
    lastMessageAuthorImage: "/landing/avatars/ethan.png",
    time: "25m",
  },
  {
    image: "/landing/avatars/brand.png",
    name: "Serenity Consulting",
    lastMessage: "Hey, just checking in—got any updates?",
    lastMessageAuthorImage: "/landing/avatars/generic.png",
    time: "32m",
    tags: tags.filter((x) => ["B2B", "Europe"].includes(x.name)),
  },
  {
    image: "/landing/avatars/ghost-2.jpeg",
    name: "Ignacio Bradtke",
    lastMessage: "currently blaming 'market conditions' on our failed",
    time: "1h",
  },
  {
    image: "/landing/avatars/neo.png",
    name: "Davion 🕶️",
    lastMessage: "2 of our clients decided to drop last minute",
    time: "1h",
    tags: tags.filter((x) => x.name === "Referral"),
  },
  {
    image: "/landing/avatars/pond.png",
    name: "Crator Brands",
    lastMessage: "Hey, just checking in—got any updates?",
    lastMessageAuthorImage: "/landing/avatars/ethan.png",
    time: "1h",
  },
];

export const ThreadsList = () => {
  const threadRef = useRef<HTMLDivElement | null>(null);

  return (
    <div className="border-r-borderLight relative flex h-full w-[230px] min-w-[230px] flex-col border-r-[0.5px]">
      <div className="relative mt-3 px-2">
        <div className="flex items-center gap-2">
          <TextFieldRoot
            placeholder="Search threads..."
            className="z-20 h-8 border-none bg-slate-800/50 text-xs transition-colors duration-300 focus-within:bg-slate-800/75 focus-within:ring-transparent"
            inputClassName="placeholder:text-slate-400/90"
          >
            <TextFieldSlot side="left" className="mr-2">
              <div className="relative h-[14px] w-[14px]">
                <Search className="h-[14px] w-[14px] stroke-slate-500" />
              </div>
            </TextFieldSlot>
          </TextFieldRoot>
          <Button
            size="sm"
            variant="ghost"
            className="h-8 bg-slate-800/50 px-2 text-slate-400"
          >
            <Plus className="h-4 w-4" />
          </Button>
        </div>
        <Button
          variant="ghost"
          size="sm"
          className="mt-1 flex h-6 items-center font-normal text-slate-300"
        >
          <ListFilterIcon className="mr-1.5 h-3 w-3 text-slate-400" />
          Filter
        </Button>
      </div>
      <div className="relative my-3 mt-2">
        {threads.map((thread, i) => (
          // biome-ignore lint/suspicious/noArrayIndexKey: won't change
          <Thread key={i} {...thread} ref={i === 1 ? threadRef : undefined} />
        ))}
        <ThreadHighlight threadRef={threadRef} />
      </div>
    </div>
  );
};
