interface DateSeparatorProps {
  date: string;
}

export const DateSeparator: React.FC<DateSeparatorProps> = ({ date }) => {
  return (
    <div className="flex select-none items-center gap-2 pb-4">
      <div className="bg-borderLight h-[0.5px] flex-1" />
      <div className="text-xs font-semibold text-slate-400">{date}</div>
      <div className="bg-borderLight h-[0.5px] flex-1" />
    </div>
  );
};
