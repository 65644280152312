import { NetworkIcon } from "lucide-react";

import { BACKGROUND_HSL, gradient } from "@/lib/gradient";
import { CardCopy } from "@/modules/landing/components/card-copy";
import { CardWrapper } from "@/modules/landing/components/card-wrapper";
import { Thread } from "@/modules/landing/hero/viewport/threads/thread";
import { threads } from "@/modules/landing/hero/viewport/threads/threads-list";

const showMask = `linear-gradient(hsla(${BACKGROUND_HSL},1),hsla(${BACKGROUND_HSL},1))`;

export const PipelineCard = () => {
  return (
    <CardWrapper className="h-[300px]">
      <figure
        className="pointer-events-none absolute left-1/2 top-0 -ml-2 mt-2 h-[calc(100%-90px)] w-[326px] -translate-x-1/2 select-none px-2 pt-2 lg:-ml-0"
        style={{
          maskImage: `${gradient(BACKGROUND_HSL, "bottom")}, ${showMask}`,
          maskSize: "100% 100px, 100% calc(100% - 100px)",
          maskRepeat: "no-repeat, no-repeat",
          maskPosition: "bottom, top",
        }}
      >
        <div className="relative flex origin-top-left scale-[0.8] gap-2">
          <div className="max-w-[230px] space-y-1 opacity-70">
            <div className="ml-4 flex items-center gap-2 border-b pb-2">
              <div className="h-2 w-3 rounded-full bg-amber-500" />
              <div className="text-sm font-medium">In progress</div>
            </div>
            <Thread {...threads[0]!} isUnread={false} />
            <div className="h-[77.5px]" />
          </div>
          <div className="max-w-[230px] space-y-1 opacity-70">
            <div className="ml-4 flex items-center gap-2 border-b pb-2">
              <div className="h-2 w-3 rounded-full bg-green-500" />
              <div className="text-sm font-medium">Closed</div>
            </div>
            <Thread {...threads[5]!} />
            <div className="h-[2px] w-full rounded-full bg-blue-400" />
            <Thread {...threads[7]!} />
          </div>
          <div className="absolute left-20 top-20 z-10 -rotate-3 rounded-lg border-[0.5px] border-slate-700 bg-slate-800 shadow-xl">
            <div className="relative h-full w-full max-w-[230px]">
              <Thread
                {...threads[1]!}
                lastMessage="team says excellent purchase!"
              />
            </div>
          </div>
        </div>
      </figure>
      <CardCopy
        icon={NetworkIcon}
        title="The DM pipeline"
        copy="Track and close your leads directly through TweetDM."
      />
    </CardWrapper>
  );
};
