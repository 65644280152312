import type { LucideIcon } from "lucide-react";

export interface CardCopyProps {
  icon: LucideIcon;
  title: string | React.ReactNode;
  copy: string;
}

export const CardCopy = ({ icon: Icon, title, copy }: CardCopyProps) => {
  return (
    <div className="absolute bottom-0 left-0 w-full space-y-2 px-6 py-5 text-[14px]">
      <div className="flex items-center gap-2 text-slate-200">
        <Icon className="h-5 w-5" />
        <h3 className="font-medium">{title}</h3>
      </div>
      <div className="text-slate-400">{copy}</div>
    </div>
  );
};
