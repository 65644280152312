import { gradient } from "@/lib/gradient";

export const Banner: React.FC<{
  url: string;
  drawer?: boolean;
}> = ({ url }) => {
  return (
    <div className="relative -ml-3 -mt-3 h-[81px] w-[calc(100%+24px)]">
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img
        src={url}
        alt="banner"
        height={81}
        className="max-h-[81px] w-full rounded-md bg-slate-800/50 object-cover opacity-50"
      />
      <div
        className="absolute -bottom-[10px] left-0 z-10 h-[80px] w-full"
        style={{
          background: gradient("220, 47%, 11%", "top"),
        }}
      />
    </div>
  );
};
