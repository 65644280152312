import {
  CheckIcon,
  CircleDashed,
  Ellipsis,
  Hexagon,
  TagIcon,
} from "lucide-react";
import Image from "next/image";

import { BACKGROUND_HSL, gradient } from "@/lib/gradient";
import { CardCopy } from "@/modules/landing/components/card-copy";
import { CardWrapper } from "@/modules/landing/components/card-wrapper";
import { Tag } from "@/modules/landing/hero/viewport/sidebar/external-tags";

import { cn } from "@ui/lib/utils";

const tags = [
  "Referral",
  "Budget Approval",
  "VIP",
  "Cold",
  "Priority",
  "New",
  "Promising",
  "Lost",
];

const showMask = `linear-gradient(hsla(${BACKGROUND_HSL},1),hsla(${BACKGROUND_HSL},1))`;

export const TagsCard = () => {
  return (
    <CardWrapper className="h-[300px]">
      <figure
        className="pointer-events-none absolute left-0 top-0 z-10 mt-2 h-[calc(100%-90px)] w-full select-none"
        style={{
          maskImage: `${gradient(BACKGROUND_HSL, "bottom")}, ${showMask}`,
          maskSize: "100% 100px, 100% calc(100% - 100px)",
          maskRepeat: "no-repeat, no-repeat",
          maskPosition: "bottom, top",
        }}
      >
        <div className="relative z-10 w-[calc(120%)] origin-top-left scale-[0.8] p-5">
          <div className="absolute left-0 top-0 flex w-max flex-col gap-[10px] p-5">
            <div className="flex gap-2 opacity-50">
              {tags.map((tag) => (
                <Tag key={tag} name={tag} color="#64748b" />
              ))}
            </div>
          </div>
          <div className="flex">
            <PopoverWrapper placeholder="Assign to..." shortcut="A">
              <MemberOption>
                <CircleDashed className="h-4 w-4 flex-shrink-0 rounded-full text-slate-400 group-data-[selected=true]:text-slate-50" />
                <div>No assignee</div>
              </MemberOption>
              <MemberOption selected>
                <Image
                  src="/landing/avatars/ethan.png"
                  width={16}
                  height={16}
                  className="h-4 w-4 rounded-full"
                  alt="Profile picture"
                />
                <div>Ethan Lerner</div>
              </MemberOption>
              <MemberOption>
                <Image
                  src="/landing/avatars/generic.png"
                  width={16}
                  height={16}
                  className="h-4 w-4 rounded-full"
                  alt="Profile picture"
                />
                <div>Timothy Okuneva</div>
              </MemberOption>
            </PopoverWrapper>
            <PopoverWrapper placeholder="Set status..." shortcut="S">
              <MemberOption>
                <Ellipsis className="h-4 w-4 flex-shrink-0 rounded-full text-slate-400 group-data-[selected=true]:text-slate-50" />
                <div>No status</div>
              </MemberOption>
              <StatusOption name="Warm" color="#f59e0b" />
              <StatusOption name="Closed" color="#22c55e" selected />
              <StatusOption name="Lost" color="#64748b" />
            </PopoverWrapper>
          </div>
        </div>
      </figure>
      <CardCopy
        icon={TagIcon}
        title="Track your prospects"
        copy="Assign team members to conversations, add tags, notes, and more."
      />
    </CardWrapper>
  );
};

const PopoverWrapper = ({
  placeholder,
  shortcut,
  children,
}: {
  placeholder: string;
  shortcut: string;
  children: React.ReactNode;
}) => {
  return (
    <div className="mx-auto mt-12 h-full w-[160px] rounded-lg border-[0.5px] border-slate-700/70 bg-slate-900 shadow-xl">
      <div className="text-muted-foreground/65 flex h-9 w-full items-center justify-between border-b border-slate-700/70 p-1.5 px-[14px] pr-1 text-sm">
        <div>{placeholder}</div>
        <kbd className="mr-2 flex h-5 w-auto items-center justify-center rounded-sm border border-slate-700/50 bg-slate-800 p-1 text-[11px] tracking-normal text-slate-50">
          {shortcut}
        </kbd>
      </div>
      <div className="p-1">{children}</div>
    </div>
  );
};

const MemberOption: React.FC<
  React.PropsWithChildren<{ selected?: boolean }>
> = ({ children, selected }) => {
  return (
    <div
      className={cn(
        "flex h-8 items-center gap-2 rounded-md px-[9px] py-[13px] text-xs text-slate-50 outline-none",
        selected && "bg-slate-700/80",
      )}
    >
      {children}
      {selected && (
        <CheckIcon className="ml-auto h-[14px] w-[14px] stroke-[2.5px] text-slate-400" />
      )}
    </div>
  );
};

const StatusOption: React.FC<{
  name: string;
  color: string;
  selected?: boolean;
}> = ({ color, selected = false, name }) => {
  return (
    <MemberOption selected={selected}>
      <Hexagon
        className="h-4 w-4 flex-shrink-0 rounded-full stroke-[3px]"
        style={{ stroke: color }}
      />
      <div className="text-xs text-slate-50">{name}</div>
    </MemberOption>
  );
};
