"use client";

import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import {
  BellDot,
  ChevronsUp,
  CircleCheck,
  type LucideIcon,
  MessagesSquare,
  Plus,
  UserCircle,
} from "lucide-react";
import type React from "react";
import { useEffect, useRef, useState } from "react";

import { CardCopy } from "@/modules/landing/components/card-copy";
import { CardWrapper } from "@/modules/landing/components/card-wrapper";
import { Thread } from "@/modules/landing/hero/viewport/threads/thread";
import { ThreadHighlight } from "@/modules/landing/hero/viewport/threads/thread-highlight";
import { threads } from "@/modules/landing/hero/viewport/threads/threads-list";

import { cn } from "@ui/lib/utils";

export const MarkDoneCard = () => {
  const threadRef = useRef<HTMLDivElement>(null);
  const [isRefReady, setIsRefReady] = useState(false);

  // biome-ignore lint/correctness/useExhaustiveDependencies: allowed
  useEffect(() => {
    if (threadRef.current) {
      setIsRefReady(true);
    }
  }, [threadRef.current]);

  return (
    <CardWrapper className="h-[500px] md:col-span-3">
      <figure className="pointer-events-none relative w-full select-none p-4">
        <div className="origin-top-left scale-[0.8]">
          <div className="relative max-w-[230px] space-y-1 opacity-40">
            <Thread {...threads[0]!} />
            <Thread {...threads[1]!} ref={threadRef} />
            <Thread {...threads[5]!} />
            <Thread {...threads[6]!} />
            <Thread {...threads[7]!} />
            <Thread {...threads[8]!} />
            {isRefReady && <ThreadHighlight threadRef={threadRef} />}
          </div>
          <div
            className={cn(
              "rounded-lg",
              "border-[0.5px] border-slate-700/70 bg-[#172033]/80 shadow-2xl backdrop-blur-md",
              "absolute left-[62.5%] top-10 z-[100]",
              "h-[375.5px] w-full max-w-lg translate-x-[-50%]",
            )}
            style={{
              WebkitBackdropFilter: "blur(12px)",
            }}
          >
            <div className="flex h-max items-center overflow-hidden border-b border-slate-700/70 px-4 py-4">
              <MagnifyingGlassIcon className="mr-3 h-4 w-4 shrink-0" />
              <div className="text-muted-foreground/65 whitespace-nowrap text-xl">
                Type a command or search...
              </div>
            </div>
            <Group>
              <GroupLabel>This thread</GroupLabel>
              <Command icon={CircleCheck} selected>
                Mark done
                <CommandKbdGroup>
                  <CommandKbd>E</CommandKbd>
                </CommandKbdGroup>
              </Command>
              <Command icon={BellDot}>
                Mark unread
                <CommandKbdGroup>
                  <CommandKbd>U</CommandKbd>
                </CommandKbdGroup>
              </Command>
            </Group>
            <Group>
              <GroupLabel>Threads</GroupLabel>
              <Command icon={MessagesSquare}>
                Search threads...
                <CommandKbdGroup>
                  <CommandKbd>⌘</CommandKbd>
                  <CommandKbd>J</CommandKbd>
                </CommandKbdGroup>
              </Command>
              <Command icon={UserCircle}>
                Switch account...
                <CommandKbdGroup>
                  <CommandKbd>⌘</CommandKbd>
                  <CommandKbd>⇧</CommandKbd>
                  <CommandKbd>M</CommandKbd>
                </CommandKbdGroup>
              </Command>
              <Command icon={Plus}>New thread...</Command>
            </Group>
          </div>
        </div>
      </figure>
      <CardCopy
        title="The Superhuman for X"
        copy="Mark threads as done, unread, and more. Instantly access up to decades of conversation history and archived threads."
        icon={ChevronsUp}
      />
    </CardWrapper>
  );
};

const Group: React.FC<React.PropsWithChildren> = ({ children }) => {
  return <div className="flex flex-col p-1.5">{children}</div>;
};

const GroupLabel: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <div className="text-muted-foreground/65 overflow-hidden px-3 py-1.5 text-xs font-medium">
      {children}
    </div>
  );
};

const Command: React.FC<
  React.PropsWithChildren<{ icon: LucideIcon; selected?: boolean }>
> = ({ icon: Icon, children, selected = false }) => {
  return (
    <div
      className={cn(
        "group relative flex cursor-default select-none items-center rounded-md px-[14px] py-[13px] text-sm outline-none",
        selected && "text-accent-foreground bg-slate-700/55",
      )}
    >
      <Icon
        className={cn(
          "mr-3 h-4 w-4 shrink-0 text-slate-400",
          selected && "text-accent-foreground",
        )}
      />
      {children}
    </div>
  );
};

const CommandKbdGroup = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div className={cn("ml-auto flex items-center space-x-[6px]", className)}>
      {children}
    </div>
  );
};

const CommandKbd = ({
  children,
  className,
}: {
  children: string;
  className?: string;
}) => {
  return (
    <kbd
      className={cn(
        "flex h-5 w-auto items-center justify-center rounded-sm border border-slate-700/50 bg-slate-800 p-1 text-xs leading-none tracking-normal text-slate-300",
        className,
      )}
    >
      {children}
    </kbd>
  );
};
