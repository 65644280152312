import { cn } from "@ui/lib/utils";

export const MessageBar = () => {
  return (
    <div
      className="group absolute bottom-5 left-5 right-5"
      style={{
        height: 48,
      }}
    >
      <div className="absolute left-0 top-0 h-full w-full rounded-xl bg-blue-500 opacity-10 blur-md transition-all" />
      <div className="relative h-full rounded-xl border-[1px] border-blue-500/30 border-slate-800 bg-[#172033] transition-all">
        <div className="z-100 relative z-30 flex h-full w-full items-center justify-between px-2">
          <div className="relative w-full">
            <div
              className="pointer-events-none fixed select-none"
              id="snippet-target"
            />
            <div
              className="flex items-center overflow-hidden border-none px-2 py-[2px] text-base leading-none outline-none ring-0"
              style={{
                height: 24,
              }}
            >
              Sure book me at ;ca
              <div className="h-full w-px bg-current" />
            </div>
            <div className="bg-popover text-popover-foreground absolute bottom-12 left-32 z-50 w-72 rounded-md border-[0.5px] p-[6px] shadow-lg">
              <Snippet
                name="Calendar link"
                content="https://cal.com/ethanlerner"
                selected
              />
              <Snippet
                name="Shared calendar link"
                content="https://cal.com/kevin+ethanlerner"
              />
              <Snippet
                name="Cancel politely"
                content="Sorry, unfortunately it looks like we won't"
              />
              <Snippet
                name="Cancellation fee"
                content="Our cancellation fee is $50"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Snippet: React.FC<{
  name: string;
  content: string;
  selected?: boolean;
}> = ({ name, content, selected }) => {
  return (
    <div
      className={cn(
        "flex h-8 w-full max-w-[284px] items-center gap-3 overflow-hidden rounded-md px-[9px] text-sm",
        selected && "bg-slate-700/80",
      )}
    >
      <div className="min-w-max overflow-hidden overflow-ellipsis whitespace-nowrap font-medium">
        {name}
      </div>
      <div className="overflow-hidden overflow-ellipsis whitespace-nowrap text-slate-400">
        {content}
      </div>
    </div>
  );
};
