import Image from "next/image";
import { forwardRef } from "react";

import { cn } from "@ui/lib/utils";

export interface ThreadProps {
  image: string;
  name: string;
  lastMessage: string;
  time: string;
  lastMessageAuthorImage?: string;
  isUnread?: boolean;
  tags?: {
    name: string;
    color: string;
  }[];
}

export const Thread = forwardRef<HTMLDivElement, ThreadProps>(
  (
    {
      image,
      name,
      lastMessage,
      time,
      lastMessageAuthorImage,
      isUnread,
      tags = [],
    },
    ref,
  ) => {
    return (
      <div className="mb-[2px] px-2" ref={ref}>
        <div
          className={cn(
            "inline-flex h-9 items-center justify-center whitespace-nowrap px-4 py-2 text-sm",
            "relative z-10 h-max w-full items-start justify-start gap-3 overflow-hidden bg-transparent px-2 hover:bg-transparent",
          )}
        >
          <div className="mt-[6px] flex min-w-6 flex-col">
            <Image
              key={image}
              className="h-6 w-6 flex-shrink-0 rounded-full bg-slate-700/50"
              width={24}
              height={24}
              src={image}
              alt={name}
            />
          </div>
          <div className="block w-full min-w-0 space-y-[0px]">
            <div className="flex w-full justify-between gap-2">
              <div className="overflow-hidden text-ellipsis whitespace-nowrap text-sm font-semibold">
                {name}
              </div>
              <div className="text-xs font-[500] text-slate-500 ">{time}</div>
            </div>
            <div className="flex items-center gap-1.5">
              {isUnread && (
                <div className="h-1.5 w-1.5 min-w-1.5 rounded-full bg-blue-400" />
              )}
              {lastMessageAuthorImage && (
                <Image
                  width={12}
                  height={12}
                  className="h-3 w-3 rounded-full bg-slate-800/50"
                  src={lastMessageAuthorImage}
                  alt={name}
                />
              )}
              <div
                className={cn(
                  "overflow-hidden text-ellipsis whitespace-nowrap text-xs text-slate-400",
                  isUnread && "font-semibold text-slate-200",
                )}
              >
                {lastMessage}
              </div>
            </div>
            {tags.length ? (
              <div className="flex flex-wrap gap-[10px] gap-y-[3px] pt-[6px] text-slate-400">
                {tags.map((tag, i) => (
                  <div
                    // biome-ignore lint/suspicious/noArrayIndexKey: won't change
                    key={i}
                    className="flex items-center gap-[8px] overflow-hidden"
                  >
                    <div
                      className="h-[10px] w-[2px] min-w-[2px] rounded-full"
                      style={{ backgroundColor: tag.color }}
                    />
                    <div className="overflow-hidden overflow-ellipsis whitespace-nowrap text-xs font-medium">
                      {tag.name}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  },
);
Thread.displayName = "Thread";
