import { Check } from "lucide-react";
import Image from "next/image";

import { cn } from "@ui/lib/utils";

export interface MessageProps {
  internal: boolean;
  time: string;
  content: string;
  authorImage?: string;
}

export const Message: React.FC<MessageProps> = ({
  content,
  time,
  internal,
  authorImage,
}) => {
  return (
    <div className="group relative flex h-max w-full items-center gap-2 px-6">
      <div
        className={cn(
          "max-w-[60%] whitespace-pre-wrap rounded-[18px] p-[6px] px-[12px] text-base text-white",
          internal
            ? "ml-auto rounded-br-md bg-blue-500"
            : "mr-auto rounded-bl-md bg-slate-800/50",
        )}
        style={{
          wordBreak: "break-word",
        }}
      >
        {content}
      </div>
      {authorImage && (
        <Image
          src={authorImage}
          width={16}
          height={16}
          className="ml-1.5 h-4 w-4 min-w-4 rounded-full"
          alt="User's avatar"
        />
      )}
      <div className="flex items-center gap-1">
        <div className="w-[40px] text-right text-xs font-[500] text-slate-400">
          {time}
        </div>
        <Check className="h-3 w-3 stroke-slate-400 stroke-[2.5px]" />
      </div>
    </div>
  );
};
