"use client";

import Link from "next/link";

import { Button } from "@ui/components/ui/button";

export const PricingNav = () => {
  return (
    <Button
      variant="ghost"
      asChild
      className="text-slate-300/90 hover:bg-transparent"
    >
      <Link
        href="#pricing"
        onClick={(e) => {
          if (e.shiftKey || e.ctrlKey || e.metaKey) return;
          e.preventDefault();

          document.getElementById("pricing")?.scrollIntoView({
            behavior: "smooth",
          });
        }}
      >
        Pricing
      </Link>
    </Button>
  );
};
