"use client";

import type { Session } from "next-auth";
import Link from "next/link";

import { Button } from "@ui/components/ui/button";

export const NavbarCta = ({ session }: { session: Session | null }) => {
  return (
    <div className="flex gap-2">
      <Button variant="outline" asChild className="border-none">
        <Link href={session?.user ? "/" : "/auth/login"}>
          {session?.user ? "Open app" : "Sign up"}
        </Link>
      </Button>
    </div>
  );
};
