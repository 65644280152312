import { DateSeparator } from "./date-separator";
import { Message } from "./message";
import { MessageBar } from "./message-bar";
import { MessageGroup } from "./message-group";

import { BACKGROUND_HSL, gradient } from "@/lib/gradient";

export const ChatContainer = () => {
  return (
    <div className="flex w-full flex-1 flex-col">
      <div className="relative h-full justify-end pb-8">
        <DateSeparator date="May 15, 2023" />
        <MessageGroup>
          <Message
            content="hey, got a sec?"
            time="09:32"
            internal
            authorImage="/landing/avatars/ethan.png"
          />
          <Message
            content="just landed a new client"
            time="09:34"
            internal
            authorImage="/landing/avatars/ethan.png"
          />
          <Message
            content="local startup, pretty cool project"
            time="09:36"
            internal
            authorImage="/landing/avatars/ethan.png"
          />
        </MessageGroup>
        <MessageGroup>
          <Message content="nice! who?" time="09:37" internal={false} />
          <Message content="awesome, congrats!" time="09:38" internal={false} />
        </MessageGroup>
        <MessageGroup>
          <Message
            content="thanks man"
            time="09:39"
            internal
            authorImage="/landing/avatars/ethan.png"
          />
          <Message
            content="btw, have you tried TweetDM?"
            time="09:40"
            internal
            authorImage="/landing/avatars/ethan.png"
          />
          <Message
            content="tool for managing multiple twitter accounts"
            time="09:41"
            internal
            authorImage="/landing/avatars/ethan.png"
          />
        </MessageGroup>
        <MessageGroup>
          <Message content="nope, what's that?" time="09:42" internal={false} />
          <Message content="sounds useful" time="09:43" internal={false} />
        </MessageGroup>
        <MessageGroup>
          <Message
            content="yeah, central inbox, team tools, ai responses"
            time="09:44"
            internal
            authorImage="/landing/avatars/ethan.png"
          />
          <Message
            content="scalable, easy onboarding too"
            time="09:45"
            internal
            authorImage="/landing/avatars/ethan.png"
          />
        </MessageGroup>
        <MessageGroup>
          <Message
            content="cool, how's the pricing?"
            time="09:46"
            internal={false}
          />
          <Message
            content="nice, let's do a demo"
            time="09:47"
            internal={false}
          />
        </MessageGroup>
        <MessageGroup>
          <Message
            content="sure, tomorrow at 2?"
            time="09:49"
            internal
            authorImage="/landing/avatars/ethan.png"
          />
        </MessageGroup>
        <MessageGroup>
          <Message content="works for me" time="09:51" internal={false} />
        </MessageGroup>
        <DateSeparator date="May 16, 2023" />
        <MessageGroup>
          <Message
            content="hey, demo at 2 today"
            time="13:30"
            internal
            authorImage="/landing/avatars/ethan.png"
          />
        </MessageGroup>
        <MessageGroup>
          <Message
            content="got it, see you then"
            time="13:31"
            internal={false}
          />
        </MessageGroup>
      </div>
      <div className="relative mx-auto w-full max-w-4xl">
        <div className="pointer-events-none absolute bottom-0 left-0 h-[44px] w-full bg-[#080E1E]" />
        <div
          className="pointer-events-none absolute bottom-[43px] left-0 h-[80px] w-full"
          style={{
            background: gradient(BACKGROUND_HSL),
          }}
        />
        <MessageBar />
      </div>
    </div>
  );
};
