import type { LucideIcon } from "lucide-react";
import type React from "react";

import { BACKGROUND_HSL, gradient } from "@/lib/gradient";

import { cn } from "@ui/lib/utils";

interface SectionHeaderProps {
  title: string;
  copy: string;
  icon: LucideIcon;
  className?: string;
  headerClassName?: string;
  copyClassName?: string;
  id?: string;
}

export const SectionHeader: React.FC<SectionHeaderProps> = ({
  title,
  copy,
  icon: Icon,
  headerClassName,
  copyClassName,
  className,
  id,
}) => {
  return (
    <div className={cn("mx-auto max-w-4xl px-4 pt-12", className)}>
      <div className="relative max-w-xl">
        <div className="absolute -top-12 flex h-[calc(100%+42px)] flex-col items-center gap-3 lg:-left-14 lg:top-0">
          <div className="relative h-[18px] w-[18px]">
            <Icon className="absolute left-0 top-0 h-[18px] w-[18px] stroke-blue-400 blur-sm" />
            <Icon className="h-[18px] w-[18px] stroke-blue-300" />
            <Icon
              className="absolute left-0 top-0 h-[18px] w-[18px] stroke-white"
              style={{
                maskImage: gradient(BACKGROUND_HSL, "bottom"),
              }}
            />
          </div>
          <div className="relative -mt-5 h-full w-px origin-top translate-x-6 -rotate-90 rounded-full bg-slate-800 lg:mt-0 lg:block lg:translate-x-0 lg:rotate-0">
            <div
              className="absolute h-full w-full rounded-full bg-blue-400 blur-[1px]"
              style={{
                maskImage: gradient(BACKGROUND_HSL, "bottom"),
              }}
            />
            <div
              className="absolute h-1/2 w-full rounded-full bg-blue-100 blur-[2px]"
              style={{
                maskImage: gradient(BACKGROUND_HSL, "bottom"),
              }}
            />
          </div>
        </div>
        <h2
          className={cn("text-4xl font-medium sm:text-5xl", headerClassName)}
          id={id}
        >
          {title}
        </h2>
        <div
          className={cn(
            "mt-6 text-lg font-medium leading-[1.35] text-slate-400",
            copyClassName,
          )}
        >
          {copy}
        </div>
      </div>
    </div>
  );
};
