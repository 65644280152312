"use client";

import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { ChatContainer } from "./viewport/chat/chat-container";
import { ViewportLayout } from "./viewport/layout";
import { Sidebar } from "./viewport/sidebar";
import { ThreadsList } from "./viewport/threads/threads-list";
import { TopBar } from "./viewport/top-bar";

export const AppViewport = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [scale, setScale] = useState(0.8);

  useEffect(() => {
    const updateScale = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth - 32;
        const newScale = Math.min(containerWidth / 1280, 1);
        setScale(newScale);
      }
    };

    updateScale();
    window.addEventListener("resize", updateScale);
    return () => window.removeEventListener("resize", updateScale);
  }, []);

  return (
    <motion.figure
      ref={containerRef}
      className="pointer-events-none relative mx-auto mb-20 ml-4 w-full max-w-5xl cursor-default select-none"
      style={{ aspectRatio: "16 / 10" }}
      initial={{
        opacity: 0,
        y: 32,
      }}
      animate={{
        opacity: 1,
        y: 0,
        transition: {
          type: "spring",
          damping: 20,
          stiffness: 50,
          mass: 1,
          delay: 0.25,
        },
      }}
    >
      <motion.div
        className="absolute h-full w-3/5 rounded-lg bg-blue-500 blur-3xl"
        initial={{
          opacity: 0,
          scale: 1.5,
        }}
        animate={{
          opacity: 0.075,
          scale: 1,
          transition: {
            delay: 0.3,
            type: "spring",
            damping: 20,
            stiffness: 50,
            mass: 1,
          },
        }}
      />
      <motion.div
        className="absolute right-0 h-full w-3/5 rounded-lg bg-purple-500 blur-3xl"
        initial={{
          opacity: 0,
          scale: 1.5,
        }}
        animate={{
          opacity: 0.075,
          scale: 1,
          transition: {
            delay: 0.3,
            type: "spring",
            damping: 20,
            stiffness: 50,
            mass: 1,
          },
        }}
      />
      <div
        className="absolute inset-0"
        style={{
          transform: `scale(${scale})`,
          transformOrigin: "top left",
          width: "1280px",
          height: "800px",
        }}
      >
        <div className="h-full w-full overflow-hidden rounded-lg border bg-slate-950 shadow-xl">
          <ViewportLayout>
            <div className="flex h-full flex-1 flex-col">
              <TopBar />
              <div className="flex h-[calc(100%-44px)] flex-row">
                <ThreadsList />
                <ChatContainer />
              </div>
            </div>
            <Sidebar />
          </ViewportLayout>
        </div>
      </div>
      <figcaption className="sr-only">
        The TweetDM inbox UI, showing a list of threads on the left side with
        various labels applied to the many conversations the user has with
        different people. In the middle are the messages for the selected
        conversation, discussing a booking for an agency. On the right lies the
        sidebar with contact details showing the tags applied to the selected
        conversation, the team member the contact was assigned to, as well as an
        input field for additional notes.
      </figcaption>
    </motion.figure>
  );
};
